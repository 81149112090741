@use "sass:meta" as ---yg3bye7brtk;
.info-box__content {
	margin: 0 auto;
	max-width: map-get($o-grid-layouts, XL) - (map-get($o-grid-gutters, M) * 2);
	padding: oSpacingByName('s6') oSpacingByName('s4');

	> *:not(:last-child) {
		margin-bottom: oSpacingByName('s4');
	}
}

.info-box__title {
	text-transform: uppercase;
	@include oTypographySans($scale: 0, $weight: 'semibold');
	color: oColorsByName('black-70');
}

.info-box__subtitle {
	display: none;
	max-width: oTypographyMaxLineWidth($optimal-characters-per-line: 130);
	@include oTypographySans($scale: 1);
	margin: 0 0 auto 0;
	color: oColorsByName('black-70');

	@include oGridRespondTo($from: S) {
			display: block;
	}
}

.info-box__body {
	@include oTypographyBody();
	color: oColorsByName('black-80');
	margin-bottom: inherit;

	p {
		margin-top: 0;
		margin-bottom: 1em;

		&:last-child {
			margin-bottom: 0.5em;
		}

		&:empty {
			padding: 0;
			margin: 0;
		}
	}

	a {
		@include oTypographyLink();
	}

	em {
		font-style: italic;
	}

	strong {
		@include oTypographySans(
			$weight: 'semibold',
			$include-font-family: false
		);
	}

	sup {
		@include oTypographySuper();
	}

	sub {
		@include oTypographySub();
	}

	figcaption {
		@include oTypographyCaption();
	}

	ol,
	ul {
		@include oTypographyList();
	}

	ol {
		@include oTypographyList($type: 'ordered', $include-base-styles: false);
	}

	ul {
		@include oTypographyList($type: 'unordered', $include-base-styles: false);
	}

	h3 {
		@include oTypographyHeading($level: 3);
	}

	h4 {
		@include oTypographyHeading($level: 4);
	}

	h5 {
		@include oTypographyHeading($level: 5);
	}

	h6 {
		@include oTypographyHeading($level: 6);
	}
}

.info-box--inverse {
	.info-box__title,
	.info-box__subtitle,
	.info-box__body,
	.info-box__body a,
	.info-box__body a:visited,
	.info-box__body a:focus,
	.info-box__body a:hover {
		color: white;
		text-decoration-color: white;
		mix-blend-mode: revert;
	}

	.info-box__body a:hover {
		mix-blend-mode: plus-lighter;
	}
}

.info-box--transparent {
	.info-box__content {
		padding-top: 0;
		padding-bottom: 0;
	}
}

;@include ---yg3bye7brtk.load-css("sass-embedded-legacy-load-done:526");