@use "sass:meta" as ---yg3bye7brtk;.topper-basic {
	background-color: var(--background-color);
	color: oColorsByUsecase('body', 'text');
}

.topper-basic--inverse {
	color: white;
}

.topper-basic__content {
	display: grid;
	grid-template-rows: fit-content(200px) fit-content(200px);
	margin: 0 auto;
	padding: oSpacingByName('s4') map-get($o-grid-gutters, default);
	max-width: map-get($o-grid-layouts, XL) - (map-get($o-grid-gutters, M) * 2);

	@include oGridRespondTo($from: M) {
		grid-template-rows: 1fr;
		grid-column-gap: 3rem;
		grid-template-columns: fit-content(650px) 1fr;
	}
}

.topper__heading {
	display: grid;
	grid-template-columns: fit-content(450px) 1fr;
	@include oGridRespondTo($from: M) {
		align-items: center;
	}
}

.topper__title {
	@include oEditorialTypographyHeading($level: 1);
	color: inherit;
	margin: 0;
	padding: 0;
}

.topper-basic__sponsor {
	display: grid;
	grid-template-columns: 1fr;
	padding-top: oSpacingByName('s1');

	@include oGridRespondTo($from: M) {
		padding-top: 0;
	}

	&.image {
		justify-items: right;
	}

	& span {
		float: right;
		align-self: center;

		@include oGridRespondTo($from: M) {
			text-align: right;
		}
	}
}

.topper__actions {
	display: flex;
	align-items: center;
	padding-left: oSpacingByName('s4');
	justify-content: flex-end;
	height: 32px;

	@include oGridRespondTo($from: M) {
		justify-content: center;
	}
}

.topper-basic__divider {
	border-top: 1px solid var(--border-bottom-color);
}

.topper-basic__border_bottom {
	height: 12px;

	.topper-stripe-1 & {
		background: var(--gradient-color-1);
	}

	.topper-stripe-2 & {
		background: linear-gradient(90deg, var(--gradient-color-1), var(--gradient-color-2)) left bottom no-repeat;
	}

	.topper-stripe-3 & {
		background: linear-gradient(90deg, var(--gradient-color-1), var(--gradient-color-2), var(--gradient-color-3)) left bottom no-repeat;
	}
}

.topper-basic__sponsor-image {
	display: block;
	padding: 0;
	top: 6px;
	position: relative;
	width: 200px;
}

.topper-basic__sponsor-text {
	@include oTypographySans($scale: 0);
}

;@include ---yg3bye7brtk.load-css("sass-embedded-legacy-load-done:524");