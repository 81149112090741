@use "sass:meta" as ---yg3bye7brtk;.layout-advert-container[data-o-ads-loaded]:not([data-o-ads-loaded=false])  {
	margin-top: calc(var(--layout-gap) * 0.5);
	margin-bottom: calc(var(--layout-gap) * 0.5);

	&:first-child {
		margin-top: 0;
		margin-bottom: 0;
	}

	& + .heading-level--h2,
	& + .layout__bg--w-heading {
		margin-top: unset;
	}
}

;@include ---yg3bye7brtk.load-css("sass-embedded-legacy-load-done:493");