@use "sass:meta" as ---yg3bye7brtk;// TODO: Remove after o3 migration
// As next-stream-page no longer imports o-normalise it causes Premium labels in x-teaser/o-teaser to be
// incorrectly styled. This is a copy-paste of the o-normalise code.
.o-normalise-visually-hidden {
	position: absolute;
	clip: rect(0 0 0 0);
	clip-path: polygon(0 0, 0 0);
	margin: -1px;
	border: 0;
	overflow: hidden;
	padding: 0;
	width: 2px;
	height: 2px;
	white-space: nowrap;
}

;@include ---yg3bye7brtk.load-css("sass-embedded-legacy-load-done:529");