@use "sass:meta" as ---yg3bye7brtk;@import './ContentLinkNoImageCentred.scss';

/* teaser overrides: consider adding these to o-teaser or x-teaser */
.o-teaser__meta:empty {
	display: none;
}


// .scope this hack to a .container so that is doesn't apply
// to teasers elsewhere on the older parts of stream pages
.container {
	.o-teaser--top-story.o-teaser--landscape {

		@include oGridRespondTo($from: M) {
			padding-right: 20px;
			.o-teaser__image-container {
				width: 49%;
				margin-bottom: 20px;
			}
		}

	}
}

;@include ---yg3bye7brtk.load-css("sass-embedded-legacy-load-done:507");