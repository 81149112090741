@use "sass:meta" as ---yg3bye7brtk;// This colour setting is the same as o-teaser
$base-color: oColorsGetTextColor('paper', 100);
$base-focus-hover: oColorsMix($base-color, $percentage: 55);
$base-muted: oColorsMix($base-color, $percentage: 60);
$inverse-color: oColorsGetTextColor('black', 100);
$inverse-background: oColorsByName('black');
$inverse-focus-hover: oColorsMix($inverse-color, $inverse-background, $percentage: 60);

@mixin setLinkColor ($font-color, $focus-hover-color, $visited-color) {
	a {
		color: $font-color;
		&:focus,
		&:hover {
			color: $focus-hover-color;
		}
		&:visited {
			color: $visited-color;
		}
	}
}

.content-link-centred {
	background: inherit;

	@include oGridRespondTo($from:L) {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		gap: oSpacingByName('s6');
		padding: 0 oSpacingByName('l18');
	}

	a {
		text-decoration: none;
	}
}

.content-link-centred__heading {
	@include oTypographyDisplay($scale: ('default': 4, 'L': 7));
	margin: oSpacingByName('s1') 0 0;
	padding: 0;

	@include setLinkColor(
		$font-color: $base-color,
		$focus-hover-color: $base-focus-hover,
		$visited-color: $base-muted
	);

	.layout__bg--inverse & {
		@include setLinkColor(
			$font-color: $inverse-color,
			$focus-hover-color: $inverse-focus-hover,
			$visited-color: $inverse-focus-hover
		);
	}
	
	@include oGridRespondTo($from:L) {
		margin-top: 0;
		text-align: center;

		.n-syndication-icon {
			margin-right: 4px;
			border-radius: 28px;
			height: 28px;
			width: 28px;
		}
	}
}

.content-link-centred__standfirst {
	@include oTypographySans($scale: 0);
	@include setLinkColor(
		$font-color: oColorsByName('black-60'),
		$focus-hover-color: $base-focus-hover,
		$visited-color: $base-muted
	);
	padding: 0;
	margin: oSpacingByName('s1') 0 0;

	@include oGridRespondTo($from:L) {
		margin-top: 0;
		text-align: center;
	}

	.layout__bg--inverse & {
		@include setLinkColor(
			$font-color: $inverse-color,
			$focus-hover-color: $inverse-focus-hover,
			$visited-color: $inverse-focus-hover
		);
	}
}

;@include ---yg3bye7brtk.load-css("sass-embedded-legacy-load-done:511");