@use "sass:meta" as ---yg3bye7brtk;.flow-row {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
	gap: 20px;
}

.flow-ln-1 {
	flex-grow: 0;
  flex-shrink: 0;
	width: 100%;
}

.flow-ln-2 {
	width: calc(50% - 10px);
}

.flow-ln-3 {
	width: #{'round(down, calc(33% - 10px), 1px)'};
}

.flow-ln-4 {
	flex-grow: 0;
  flex-shrink: 1;
	// width: calc(25% - ((row-gap * (num - 1)) / num))
	width: calc(25% - (60px / 4))
}

.flow-ln-5 {
	width: 20%;
}

.flow-ln-6 {
	width: 16.6%;
}

.flow-ln-6 {
	width: 14.2%;
}

.align-self--center {
	align-self: center;
}

@include oGridRespondTo($until:L) {
	.SM\:flow-ln-1 {
		flex-grow: 0;

		flex-shrink: 0;
		width: 100%;
	}
	.SM\:flow-ln-2 {
		width: calc(50% - 10px)
	}
	.SM\:flow-ln-3 {
		// SCSS doesn't allow this uses of nested CSS function so we need to escape it
		width: #{'round(down, calc(33% - 10px), 1px)'};
	}
	.SM\:flow-ln-4 {
		flex-grow: 0;
		flex-shrink: 1;
		// width: calc(25% - ((row-gap * (num - 1)) / num))
		width: calc(25% - (60px / 4))
	}
}

@include oGridRespondTo($from:L) {
	.LX\:flow-ln-1 {
		flex-grow: 0;

		flex-shrink: 0;
		width: 100%;
	}
	.LX\:flow-ln-2 {
		width: calc(50% - 10px);
	}
	.LX\:flow-ln-3 {
		width:  #{'round(down, calc(33% - 10px), 1px)'};
	}
	.LX\:flow-ln-4 {
		flex-grow: 0;
		flex-shrink: 1;
		// width: calc(25% - ((row-gap * (num - 1)) / num))
		width: calc(25% - (60px / 4))
	}
}

/* teaser overrides */
.container {
	.o-teaser {
		margin-bottom: 0;
	}

	.o-teaser--small {
		border-bottom: none;
		padding-bottom: unset;

		@include oGridRespondTo($until:M) {
			.o-teaser__image-container {
				display: inherit;
			}
		}
	}

}

;@include ---yg3bye7brtk.load-css("sass-embedded-legacy-load-done:499");